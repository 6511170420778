export default function (option = {}) {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: 30,
      right: 30,
      bottom: 20,
      top: 30
    },
    xAxis: [
      {
        type: 'category',
        name: option.xAxisName || '月',
        boundaryGap: false,
        axisLine: {
          symbol: ['none', 'arrow']
        },
        data: option.xAxisData || []
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: option.yAxisName || 'kg',
        axisLine: {
          show: true,
          symbol: ['none', 'arrow']
        },
        splitLine: { // 控制网格线是否显示
          show: false, // 网格线是否显示
        },
        max: option.yAxisMax,
        scale: option.yAxisScale
      }
    ],
    series: option.series || []
  };
}
