<template>
  <div
    class="report"
  >
    <div
      id="report"
      class="container"
    >
      <h3 class="container-title">
        儿童健康成长动态测评报告
      </h3>
      <el-row>
        <el-col :span="12">
          测评时间：{{ evalData.evalDate||'-' }}
        </el-col>
        <el-col
          :span="12"
          class="right"
        >
          {{ orgName }}
        </el-col>
      </el-row>
      <!-- 基本信息 -->
      <div
        id="test"
        class="panel star split"
      >
        <div class="title">
          宝贝基本信息
        </div>
        <el-row>
          <el-col :span="24">
            <span class="info-label">名字：</span>{{ evalData.name||'-' }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">性别：</span>{{ evalData.childrenSex|sexConvert }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label"> 出生年月日：</span>{{ evalData.childrenBirthday }}（{{ evalData.evalAge }}）
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">近日身长/身高：</span>{{ evalData.height||'-' }}cm
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">近日体重：</span>{{ evalData.bodyWeight||'-' }}kg
          </el-col>
        </el-row>
        <br>
        <br>
        <el-row>
          <el-col :span="24">
            <span class="info-label">孕周（选填）：</span>{{ evalData.gestationalWeeks||'-' }}周
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">出生体重（选填）：</span>{{ evalData.bornWeight||'-' }}kg
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">产时疾病史记录（选填）：</span>{{ evalData.birthRecord||'-' }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">家族疾病史记录（选填）：</span>{{ evalData.familyDiseaseRecord||'-' }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="info-label">过敏疾病史记录（选填）：</span>{{ evalData.beforeBirthRecord||'-' }}
          </el-col>
        </el-row>
      </div>
      <!-- 体格评价 -->
      <template v-if="chartOption.data.haveEvalRecord">
        <div class="panel long split">
          <div class="title">
            体格发育指标动态测量结果与评价
          </div>
          <div class="sub-title">
            0-2岁区：
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <div
                class="chart-title"
                style="margin-top:10px"
              >
                身长测量结果评价
              </div>
              <div class="chart-box">
                <Chart :option="chartOption.option.height||{}" />
              </div>
              <div class="detail">
                {{ chartOption.data.heightEvaluation||'-' }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="chart-title">
                体重测量结果评价
              </div>
              <div class="chart-box">
                <Chart :option="chartOption.option.weight||{}" />
              </div>
              <div class="detail">
                {{ chartOption.data.weightEvaluation||'-' }}
              </div>
            </el-col>
          </el-row>
        <!-- <el-row :gutter="20">
          <el-col
            v-if="twoYearsOld"
            :span="12"
          >
            <div class="chart-title">
              体型匀称度测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption.option.bodyMassIndex||{}" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chart-box flex-chart-title text">
              体型匀称度评价：{{ chartOption.data.bodyMassIndexDesc||'-' }} <br>
              (小于2岁时，无百分位图，体型匀称度评价：需申请专家报告评价)
            </div>
          </el-col>
        </el-row> -->
        </div>
      </template>
      <template v-if="chartOption2.data.haveEvalRecord">
        <div class="panel long">
          <div class="title">
            体格发育指标动态测量结果与评价
          </div>
          <div class="sub-title">
            大于2岁体格评价:
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <div
                class="chart-title"
                style="margin-top:10px"
              >
                身高测量结果评价
              </div>
              <div class="chart-box">
                <Chart :option="chartOption2.option.height||{}" />
              </div>
              <div class="detail">
                {{ chartOption2.data.heightEvaluation||'-' }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="chart-title">
                体重测量结果评价
              </div>
              <div class="chart-box">
                <Chart :option="chartOption2.option.weight||{}" />
              </div>
              <div class="detail">
                {{ chartOption2.data.weightEvaluation||'-' }}
              </div>
            </el-col>
          </el-row>
          <!-- <div class="split"> -->
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="chart-title">
                体型匀称度测量结果评价
              </div>
              <div class="chart-box">
                <Chart :option="chartOption2.option.bodyMassIndex||{}" />
              </div>
              <div class="detail">
                {{ chartOption2.data.bodyMassIndexEvaluation||'-' }}
              </div>
            </el-col>
          </el-row>
          <!-- </div> -->
          <el-row>
            <el-col :span="24">
              <div class="text">
                体型匀称度评价：{{ chartOption2.data.bodyMassIndexDesc||'-' }} <br>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>

      <!-- 测评成绩单 -->
      <div class="panel split">
        <div class="title">
          测评成绩单
        </div>
        <div class="note">
          <el-row>
            <el-col :span="24">
              <span class="base-label">名字：</span> {{ evalData.name||'-' }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="base-label">性别：</span> {{ evalData.childrenSex|sexConvert }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="base-label">年龄：</span>{{ evalData.childrenBirthday }}（{{ evalData.evalAge }}）
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="base-label">身高/身长结论：</span>{{ evalData.heightEvaluation||'-' }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span class="base-label">体重结论：</span>{{ evalData.weightEvaluation||'-' }}
            </el-col>
          </el-row>
          <el-row v-if="evalData.bodyMassIndexEvaluation">
            <el-col :span="24">
              <span class="base-label">匀称度结论：</span>{{ evalData.bodyMassIndexEvaluation||'-' }}
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :span="24"
              class="text-right"
              style="margin-bottom:-20px"
            >
              本次测评年龄段采用{{ evalData.evalMonth||'-' }}月标准
            </el-col>
          </el-row>
        </div>
        <div>
          <ResultTable :list="evalData.evalProductItemResult" />
        </div>
        <div
          class="split"
          style="line-height: 24px;"
        >
          温馨提示：<br>
          1.发育商动态测评得分在10%内上下波动可能属于正常波动，超过10%需要咨询医生<br>
          2.请高度重视项目通过等级的红色警示
        </div>
      </div>

      <!-- 本次测评得分与前次测评得分比较 -->
      <div class="panel split">
        <div class="panel-sub">
          本次测评得分与前次测评得分比较
        </div>
        <el-row>
          <el-col :span="8">
            成长指标测评总分差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.itemDiffTotalScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.itemDiffTotalScore ||'-' }}</span>
          </el-col>
          <el-col :span="8">
            语言与感知测评差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.item1DiffScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.item1DiffScore ||'-' }}</span>
          </el-col>
          <el-col :span="8">
            平衡与大运动测评差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.item2DiffScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.item2DiffScore||'-' }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            精细动作测评差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.item3DiffScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.item3DiffScore ||'-' }}</span>
          </el-col>
          <el-col :span="8">
            适应能力测评差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.item4DiffScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.item4DiffScore ||'-' }}</span>
          </el-col>
          <el-col :span="8">
            社会行为测评差别：
            <span
              class="green"
              :class="{red:/\-/.test(evalData.resultDifference && evalData.resultDifference.item5DiffScore)}"
            >{{ evalData.resultDifference && evalData.resultDifference.item5DiffScore ||'-' }}</span>
          </el-col>
        </el-row>
      </div>

      <!-- 预警行为提示 -->
      <div class="panel split">
        <div class="panel-sub">
          <img
            src="./images/warn.svg"
            class="warn"
          > 预警行为提示
        </div>
        <div v-html="warnStr||'-'" />
      </div>

      <!-- 专家报告解读 -->
      <template v-if="evalData.evalOpinion">
        <div class="title">
          专家报告解读
        </div>
        <div class="panel text split">
          {{ evalData.evalOpinion }}
          <!-- <p class="right expert">
        专家解读：XXX
      </p> -->
        </div>
      </template>

      <!-- 发育商评价 -->
      <div class="panel split">
        <div class="title purple">
          发育商动态测评结果(综合)
        </div>
        <div class="chart-box">
          <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'itemTotalScore',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
        </div>
        <div class="pic-box">
          <div class="text-center">
            <div class="pic-title">
              <p class="prefix">
                本次成长测评
              </p>
              总评级
            </div>
            <img
              v-if="evalData.totalEvaluationLevel"
              class="total-img"
              :src="require(`./images/level/child/${evalData.totalEvaluationLevel}.png`)"
            >
            <div class="pic-level">
              {{ evalData.totalEvaluationLevel|levelBaby }}
            </div>
          </div>
          <div v-html="evalData.reportInterpretation||'-'" />
        </div>
      </div>

      <div class="panel">
        <div class="title gray">
          发育商动态测评结果 (单项)
        </div>

        <div class="split">
          <div class="sub-title">
            一、语言与感知动态测评结果：
          </div>
          <div class="chart-box">
            <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item1Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
          </div>
          <!-- 本次测评语言与感知结果评价 -->
          <div class="pic-box">
            <div class="text-center">
              <div class="pic-title">
                <p class="prefix">
                  本次成长测评
                </p>
                语言与感知评级
              </div>
              <img
                v-if="evalData.item1EvaluationLevel"
                :src="require(`./images/level/icon/${evalData.item1EvaluationLevel}.png`)"
              >
              <div class="pic-level">
                {{ evalData.item1EvaluationLevel|level }}
              </div>
            </div>
            <div v-html="evalData.item1Interpretation||'-'" />
          </div>

          <div class="sub-title">
            二、平衡与大运动动态测评结果：
          </div>
          <div class="chart-box">
            <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item2Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
          </div>
          <!-- 本次测评平衡与大运动结果评价 -->
          <div class="pic-box">
            <div class="text-center">
              <div class="pic-title">
                <p class="prefix">
                  本次成长测评
                </p>
                平衡与大运动评级
              </div>
              <img
                v-if="evalData.item2EvaluationLevel"
                :src="require(`./images/level/icon/${evalData.item2EvaluationLevel}.png`)"
              >
              <div class="pic-level">
                {{ evalData.item2EvaluationLevel|level }}
              </div>
            </div>
            <div v-html="evalData.item2Interpretation||'-'" />
          </div>
        </div>

        <div class="split">
          <div class="sub-title">
            三、精细动作动态测评结果：
          </div>
          <div class="chart-box">
            <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item3Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
          </div>
          <div class="pic-box">
            <div class="text-center">
              <div class="pic-title">
                <p class="prefix">
                  本次成长测评
                </p>
                精细动作评级
              </div>
              <img
                v-if="evalData.item3EvaluationLevel"
                :src="require(`./images/level/icon/${evalData.item3EvaluationLevel}.png`)"
              >
              <div class="pic-level">
                {{ evalData.item3EvaluationLevel|level }}
              </div>
            </div>
            <div v-html="evalData.item3Interpretation||'-'" />
          </div>

          <div class="sub-title">
            四、适应能力动态测评结果：
          </div>
          <div class="chart-box">
            <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item4Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
          </div>
          <!-- 本次测评适应能力结果评价 -->
          <div class="pic-box">
            <div class="text-center">
              <div class="pic-title">
                <p class="prefix">
                  本次成长测评
                </p>
                适应能力评级
              </div>
              <img
                v-if="evalData.item4EvaluationLevel"
                :src="require(`./images/level/icon/${evalData.item4EvaluationLevel}.png`)"
              >
              <div class="pic-level">
                {{ evalData.item4EvaluationLevel|level }}
              </div>
            </div>
            <div v-html="evalData.item4Interpretation||'-'" />
          </div>
        </div>

        <div class="split">
          <div class="sub-title">
            五、社会行为动态测评结果：
          </div>
          <div class="chart-box">
            <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item5Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
          </div>

          <!-- 本次测评社会行为结果评价 -->
          <div class="pic-box">
            <div class="text-center">
              <div class="pic-title">
                <p class="prefix">
                  本次成长测评
                </p>
                社会行为评级
              </div>
              <img
                v-if="evalData.item5EvaluationLevel"
                :src="require(`./images/level/icon/${evalData.item5EvaluationLevel}.png`)"
              >
              <div class="pic-level">
                {{ evalData.item5EvaluationLevel|level }}
              </div>
            </div>
            <div v-html="evalData.item5Interpretation||'-'" />
          </div>
        </div>
      </div>

      <div class="panel split">
        <div class="title">
          专家建议
        </div>
        <div>
          <br
            v-for="item in 5"
            :key="item"
          >
        </div>
      </div>

      <!-- 二维码 -->
      <div
        v-if="0"
        class="panel contact split"
      >
        <div
          class="qr"
        >
          <img src="./images/qr.png">
          <p>公众号二维码</p>
        </div>
      </div>

      <!-- 地址 -->
      <div
        class="contact split"
        style="margin-top:24px"
      >
        <div class="qr">
          <img src="./images/qr.png">
          <p>咨询专家</p>
        </div>
        <div class="address right">
          <p>{{ addr.reportHospitalName||'-' }}</p>
          <p>地址：{{ addr.reportAddress||'-' }}</p>
          <p>联系电话：{{ addr.reportTel||'-' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEvalResultData, reportPicAddressTel, queryByEvalRecordId, evalRecord, growthStandardData
} from '@/api';
import { getUserInfo } from '@/utils/auth';
import { getAge } from '@/utils/utils';
import chartData from './option';

export default {
  components: {
    Chart: () => import('@/components/Chart/Echart.vue'),
    ResultTable: () => import('./resultTable.vue')
  },
  filters: {
    sexConvert(value) {
      const sexMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return sexMap[value] || '-';
    },
    strConvert(val) {
      // 报告-宝宝等级:   item1EvaluationLevel   item2EvaluationLevel  item3EvaluationLevel   item4EvaluationLevel  item5EvaluationLevel
      if (val) {
        return val;
      }
      return '-';
    },
    level(val) {
      const map = {
        SHINING_BABY: '优秀',
        POTENTIAL_BABY: '良好',
        ATTENTION_BABY: '合格',
        WARNING_BABY: '关注',
      };
      return `${map[val]}等级`;
    },
    levelBaby(val) {
      const map = {
        SHINING_BABY: '优秀',
        POTENTIAL_BABY: '良好',
        ATTENTION_BABY: '合格',
        WARNING_BABY: '关注',
      };
      return `${map[val]}宝宝`;
    }
  },
  data() {
    return {
      evalData: {},
      evalRecordData: {},
      evalRecord2Data: {},
      warnStr: '无',
      addr: {},
      // 体格数据
      chartOption: {
        data: {},
        option: {
          height: {},
          weight: {},
          bodyMassIndex: {}
        }
      },
      // 大于三岁体格数据
      chartOption2: {
        data: {},
        option: {
          height: {},
          weight: {},
          bodyMassIndex: {}
        }
      },
    };
  },
  computed: {
    evalRecordId() {
      return Number(this.$route.query.evalRecordId);
    },
    id() {
      return Number(this.$route.query.id);
    },
    orgName() {
      const user = getUserInfo() || {};
      return user.orgName || '-';
    },
    twoYearsOld() {
      if (!this.evalData.childrenBirthday) return false;
      const age = getAge(this.evalData.childrenBirthday, { getYear: true });
      return typeof age === 'number' && age >= 2;
    },
    getMonth() {
      if (!this.evalData.childrenBirthday) return false;
      const age = getAge(this.evalData.childrenBirthday, { getMonth: true });
      return age;
    },
    getAge() {
      return getAge();
    },
  },
  created() {
    this.init();
  },
  methods: {
    chartData,
    async init() {
      await this.getEvalResultData();
      this.evalRecord();
      this.evalRecord2();
      this.queryByEvalRecordId();
      this.reportPicAddressTel();
    },
    // 测评报告
    getEvalResultData() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      return getEvalResultData(params).then((res) => {
        this.evalData = res.data || {};
      });
    },
    convertEvalData(list = [], configs = {}) {
      const xAxisData = [];
      const data = [];
      list.forEach((item) => {
        xAxisData.push(item[configs.dateKey || 'month']);
        data.push(item[configs.key]);
      });
      return chartData({
        xAxisName: configs.xAxisName,
        yAxisName: configs.yAxisName,
        xAxisData,
        yAxisMax: configs.yAxisMax,
        yAxisScale: configs.yAxisScale,
        series: [{
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top',
            fontSize: 10,
            rotate: 45
          },
          data
        }],
      });
    },
    // 获取0-24月份数据
    evalRecord() {
      const timeObj = {
        startMonth: 0,
        endMonth: 24
      };
      const params = {
        id: this.id,
        evalRecordId: this.evalRecordId,
        ...timeObj
      };
      evalRecord(params).then((res) => {
        const { evalRecords, ...data } = res.data || {};
        this.chartOption.data = data || {};
        if (!data.haveEvalRecord) return;
        this.getChartOption(timeObj, (arr) => {
          this.setChart(evalRecords || [], arr, 'chartOption');
        });
      });
    },
    getChartOption(timeObj, cb) {
      // const arr = ['height','weight','bodyMassIndex']
      // 位置匹配
      const arr = ['HEIGHT_PERCENT', 'WEIGHT_PERCENT', 'BODY_MASS_INDEX'];
      const tmp = Array.from(arr, (growthClass) => {
        const res = this.growthStandardData({
          growthClass,
          ...timeObj
        });

        return res;
      });
      return Promise.all(tmp).then((res) => cb(res));
    },
    setChart(evalRecords, arr, optionKey) {
      const unitMap = {
        height: 'CM',
        weight: 'Kg',
        bodyMassIndex: 'Kg/㎡',
      };
      Object.keys(this.$data[optionKey].option).forEach((key, index) => {
        this.$data[optionKey].option[key] = this.convertEvalData(evalRecords, {
          key,
          yAxisName: unitMap[key],
          xAxisName: '月',
          dateKey: 'monthAge',
          yAxisScale: true
        });
        this.$data[optionKey].option[key].series = [...this.$data[optionKey].option[key].series, ...arr[index]];
      });
    },
    // 成长标准数值
    growthStandardData(pm) {
      const params = {
        sex: this.evalData.childrenSex,
        ...pm
      };
      return growthStandardData(params).then((res) => {
        if (!(res.data || []).length) return [];
        const series = [];
        const keys = {
          threeRd: 3,
          tenTh: 10,
          twentyFiveTh: 25,
          fiftyTh: 50,
          seventyFiveTh: 75,
          ninetyTh: 90,
          ninetySevenTh: 97,
        };
        (res?.data || []).forEach((item) => {
          Object.keys(keys).forEach((key, index) => {
            if (!series[index]) {
              this.$set(series, index, {
                name: keys[key],
                type: 'line',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 0.5
                    }
                  }
                },
                endLabel: {
                  show: true,
                  fontSize: 7.5,
                  lineHeight: 0,
                  formatter(obj) {
                    return obj.seriesName;
                  }
                },
                data: []
              });
            }
            series[index].data.push(item[key]);
          });
        });

        const arr = series.filter((item) => item.data.some((sub) => sub));
        return arr;
      }, () => []);
    },
    // 获取37月份数据
    evalRecord2() {
      const timeObj = {
        startMonth: 24,
        endMonth: 81
      };
      const params = {
        id: this.id,
        evalRecordId: this.evalRecordId,
        ...timeObj
      };
      evalRecord(params).then((res) => {
        const { evalRecords, ...data } = res.data || {};
        this.chartOption2.data = data || {};
        if (!data.haveEvalRecord) return;
        this.getChartOption(timeObj, (arr) => {
          this.setChart(evalRecords || [], arr, 'chartOption2');
        });
      });
    },
    // 获取预警信息
    queryByEvalRecordId() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      queryByEvalRecordId(params).then((res) => {
        this.warnStr = res.data?.warningBehavior || '无';
      });
    },
    // 联系方式
    reportPicAddressTel() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      reportPicAddressTel(params).then((res) => {
        this.addr = res?.data || {};
      });
    }
  },
};
</script>
<style lang="less">
.el-row + .el-row{
  margin-top: 10px;
}
</style>

<style lang="less" scoped>
.report{
  *{
    box-sizing: border-box;
  }
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  line-height: 1.5;
  font-family: SimHei;
  .container{
    width: 842px;
    margin: 0 auto;
    padding: 0 50px;
    background: url('./images/bg.jpg') repeat-y;
    background-size: contain;
    box-sizing: border-box;

    &-title{
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: #333;
      text-align: center;
      padding-top: 24px;
      margin-bottom: 46px;
      margin-top: 0;
    }
  }
  .center{
    text-align: center;
  }
  .left{
    text-align: left;
  }
  .right{
    text-align: right;
  }
  .head{
    font-size: 18px;
  }
  .title{
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    .top{
      margin: -16px -17px 16px;
      height: 60px;
      line-height: 60px;
      font-weight: 600;
      font-size: 24px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #000;
    }
    &.purple{
      background: #EFDDDD;
      .top;
    }
    &.gray{
      background: #CCE1EC;
      .top;
    }
  }
  .sub-title{
    font-weight: 600;
    font-size: 14px;
    border-left: 4px solid #85C6CE;
    padding-left: 7px;
  }
  .note{
    font-size: 14px;
    color: #333;
  }
  .panel{
    padding: 12px 16px 15px;
    border: 1px solid #E5E5E5;
    border-top: 4px solid #85C6CE;
    border-radius: 4px;
    background: #fff;
    margin-top: 12px;
    margin-bottom: 40px;
    box-sizing: border-box;
    &-sub{
      font-weight: 600;
      font-size: 18px;
      color: #333;
      margin-bottom: 16px;
    }
    .warn{
      width: 36px;
      height: 28px;
      vertical-align: bottom;
    }
  }
  .base{
    font-size: 14px;
  }
  .chart-box{
    height: 192px;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .chart-title{
    margin:0;
  }
  .long .chart-box{
    height: 230px;
    margin-bottom: 20px;
  }
  .flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text{
    line-height: 1.5;
  }
  .result{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pic-box{
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 40px;
    margin-top: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    div:nth-child(2){
      font-size: 14px;
      line-height: 1.75;
      font-weight: normal;
    }
    .prefix{
      margin-bottom: 0;
      font-weight: 600;
      color: #FF3730;
      font-size: 16px;
    }
    .pic-title{
      margin-bottom: 8px;
      width: 180px;
      font-weight: 600;
      color: #FF3730;
      font-size: 16px;
    }
    .pic-level{
      // width: 78px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: #FF3730;
    }
    img{
      height:80px;
      width:80px;
      // margin-right: 15px;
    }
    // .total-img{}
  }
  .pic-box + .pic-box{
    margin-top: 50px;
  }
  .expert{
    margin-bottom: 0;
  }
  .contact{
    @width:78px;

    display: flex;
    justify-content: space-between;
    .qr{
      width: @width;

      img{
        width:@width;
        height: @width;
        box-shadow:0 0 1px 1px #eaeaea;
      }
      p{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
  .star{
    background-image: url('./images/star.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 200px 200px;
  }
}
.info-label{
  width: 200px;
  display: inline-block;
  font-weight: 600;
}
.base-label{
  width: 130px;
  display: inline-block;
  font-weight: 600;
}

.green{
  color:#85C6CE;
}
.red{
  color: #FF3730;
}
/deep/.el-table {
  th>.cell{
    color: #000;
  }
  .cell{
    color: #333;
  }
}
.split{
  page-break-after:always;
}
</style>
